export const themes = {
  1: "Стандарты коммуникации: общение",
  2: "Стандарты коммуникации: дизайн",
  3: "Целевые аудитории учреждений культуры",
  4: "Ремонты в учреждениях культуры",
  5: "Аудитория библиотек и практики чтения",
  6: "Комплектование библиотечных фондов",
  7: "Кейсы и статистика городов мира",
  8: "Культурно-массовые мероприятия",
  9: "Городская среда",
  10: "Библиотечные кейсы",
  11: "Поиск средств",
  12: "Оптимизация и автоматизация процессов",
  13: "Сохранность библиотечных фондов",
  14: "Редкие книги",
  15: "Электронный каталог",
};

export const types = {
  1: "методическая разработка",
  2: "исследование",
  3: "шаблон документа",
  4: "повышение квалификации",
  5: "семинар",
  6: "конференция",
  7: "видео",
  8: "аудио",
  9: "фото",
  10: "все сложно",
  11: "интервью",
  12: "СМИ",
  13: "статья",
};

export const parts = {
  1: "Методические материалы",
  2: "Публикации",
  3: "События",
  4: "Образовательные программы",
  // 5: "",
  6: "Подкасты",
  7: "Исследования",
};

export const years = {
  2024: 2024,
  2023: 2023,
  2022: 2022,
  2021: 2021,
  2020: 2020,
  2019: 2019,
  2018: 2018,
  2017: 2017,
  2016: 2016,
  2015: 2015,
  2014: 2014,
};

export const data = [
  {
    id: "1",
    part: ["Методические материалы"],
    title:
      "Рекомендации по внедрению порядка возмещения стоимости и замены документов, утраченных из фонда библиотеки",
    description: [
      "Ситуации, когда читатели теряют или портят объекты библиотечного фонда, часто приводят к напряжению между библиотекарем и читателем. Со стороны читателя напряжение может быть вызвано чувством вины и неловкости от того, что он утратил или испортил чужую вещь. Библиотекарь, в свою очередь, может волноваться от того, что не знает, как убедить читателя возместить ущерб, чувствовать себя уязвленным, так как вынужден просить о чем-то чужого человека, или неуверенным в процедурах, которые следует выполнить в случае, если книга утрачена.",
      "Все перечисленное — источник конфликтных ситуаций, которые плохо влияют на отношения между библиотекой и читателями. Поэтому мы написали это методическое руководство для себя и других библиотек. Оно призвано сделать работу с читателями, потерявшими библиотечные книги, безболезненной — и даже больше: приятной и полезной.",
    ],
    theme: [
      "Стандарты коммуникации: общение",
      "Комплектование библиотечных фондов",
    ],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 3,
        text: "Рекомендации по внедрению порядка возмещения стоимости и замены документов, утраченных из фонда библиотеки",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/утрата_из_фонда_библиотеки.pdf",
        },
      },
      {
        id: 8,
        text: "Шаблоны Порядка возмещения пользователями стоимости утраченных документов библиотечного фонда и Порядка замены пользователем утраченного документа библиотечного фонда",
        btn: {
          text: "Скачать DOCX",
          url: "https://nekrasovka.ru/file/1591618994.docx",
        },
      },
      {
        id: 9,
        text: "Таблицы для проведения расчетов в целях возмещения стоимости утраченного документа",
        btn: {
          text: "Скачать XLSX",
          url: "https://nekrasovka.ru/file/1591373355.xlsx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "2",
    part: ["Методические материалы"],
    title: "Стандарты коммуникации с посетителями: шаблоны приказов",
    description: [
      "С 2018 года наша библиотека ведет работу по качественному улучшению профессиональных компетенций библиотекарей в сфере коммуникации с читателями. На основе разбора десятков кейсов и многочисленных тренингов мы разработали и внедряем «Стандарты коммуникации с читателями».",
      "Предлагаемые проекты приказов помогут описать и начать внедрение системы стандартов коммуникации с вашими посетителями. Они разрабатывались нами для библиотек, но могут быть также адаптированы для других учреждений культуры.",
    ],
    theme: ["Стандарты коммуникации: общение"],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 20,
        text: "Проект приказа о стандартах очного общения с читателями",
        btn: {
          text: "Скачать DOC",
          url: "https://nekrasovka.ru/file/1582312778.docx",
        },
      },
      {
        id: 21,
        text: "Проект приказа о стандартах делового общения по телефону",
        btn: {
          text: "Скачать DOC",
          url: "https://nekrasovka.ru/file/1582312767.docx",
        },
      },
      {
        id: 22,
        text: "Проект приказа о стандартах рабочей переписки по электронной почте",
        btn: {
          text: "Скачать DOC",
          url: "https://nekrasovka.ru/file/1582312791.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover3.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "3",
    part: ["Исследования"],
    title: "Концепция стилистических решений для ремонтов в библиотеках Москвы",
    description: [
      "Мы разработали подробные рекомендации по зонированию, оборудованию и оформлению пространств библиотек Москвы. Документ создавался нами совместно с профессиональными архитекторами, благодаря чему он учитывает как специфику библиотечной работы, так и актуальные архитектурные нормы и стандарты качества.",
      "Концепция основана на анализе 402 библиотечных помещений: планов БТИ и техпаспортов, фотоматериалов. Отдельные библиотеки архитекторы посещали и изучали их пространства дополнительно на месте. А также мы изучили лучшие мировые практики организации и оформления современных общественных пространств, в том числе в ведущих библиотеках мира.",
      "Мы опираемся на этот документ при разработке архитектурных решений и оказываем консультации на его основе. Если вы хотите рассказать нам об опыте использования Концепции или задать вопросы, напишите на remont@nekrasovka.ru",
    ],
    theme: ["Ремонты в учреждениях культуры", "Городская среда"],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 23,
        text: "Полная версия Концепции",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/концепция_стилистических_решений_для_разработки_и_реализации_проекта_московские_библиоцентры.pdf",
        },
      },
      {
        id: 24,
        text: "Краткая версия Концепции",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/концепция_стилистических_решений_для_разработки_и_реализации_проекта_московские_библиоцентры_краткая.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover15.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "4",
    part: ["Методические материалы"],
    title: "Положение о приеме и оценке пожертвований",
    description: [
      "Пожертвования в фонд — частая и хорошая практика. Они позволяют библиотекам комплектовать фонды не только через покупки, но и благодаря подаркам от издательств, читателей и других жертвователей.",
      "В то же время, в соответствии с действующим законодательством, пожертвования невозможно принять к учету без оценки. Организация оценки — нетривиальная задача.",
      "В 2019 году мы провели работу по изучению особенностей оценки пожертвований в контексте изменений в законодательстве РФ о бюджетном учете — и подготовили проект Положения о приеме и оценке пожертвований. Мы используем его в своей работе и рекомендуем коллегам из других библиотек России.",
    ],
    theme: ["Комплектование библиотечных фондов"],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 4,
        text: "Шаблон Положения о работе с пожертвованиями в виде печатных изданий или документов иной формы.",
        btn: {
          text: "Скачать DOCX",
          url: "https://nekrasovka.ru/file/1613665202.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover3.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "5",
    part: ["События"],
    title: "Стандарты коммуникации с читателями",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции состоялся интенсив для сотрудников и руководителей библиотек по ключевым принципам успешного взаимодействия с посетителями, связи внешней и внутренней коммуникации библиотеки и алгоритмам действия в повседневных и в конфликтных ситуациях.",
      "Интенсив состоял из лекционной и интерактивной частей, первая представлена в видеозаписях.",
    ],
    theme: ["Стандарты коммуникации: общение"],
    type: ["методическая разработка", "видео", "все сложно", "конференция"],
    actions: [
      {
        id: 25,
        text: "Стандарты коммуникации с читателями: вводная лекция. Алина Богаткова, первый заместитель генерального директора библиотеки им. Н.А. Некрасова",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=w8rqfh-Y5C0&feature=emb_logo",
        },
      },
      {
        id: 26,
        text: "Общие принципы коммуникации. Мария Игнатьева, зав. отделом научно-методической и научно-исследовательской работы библиотеки им. Н.А. Некрасова",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=U60Rjaz12xQ",
        },
      },
      {
        id: 27,
        text: "Принципы коммуникации в конфликтных ситуациях. Екатерина Фадейкина, заведующая отделом библиотечно-информационного обслуживания библиотеки им. Н.А. Некрасова",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=glOkYLY6lwo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-5.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "6",
    part: ["Методические материалы"],
    title: "Цифровая коммуникация для учреждений культуры",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» рассказывает о простейшей цифровой стратегии для небольших библиотек, музеев, галерей, культурных центров и других учреждений культуры. В книге даны советы по проектированию сайтов, поиску подрядчиков, работе в соцсетях и привлечению посетителей.",
    ],
    theme: ["Стандарты коммуникации: общение"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 69,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/цифровая_коммуникация_для_учреждений_культуры.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover47.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "7",
    part: ["Методические материалы"],
    title: "Мебель в учреждениях культуры",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» рассказывает о том, как в условиях недостатка бюджета подобрать и купить мебель для учреждения культуры. В первую очередь рассматриваются необычные возможности: модификация готовой мебели и заказы у частных мастеров.",
    ],
    theme: ["Ремонты в учреждениях культуры", "Стандарты коммуникации: дизайн"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 70,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/мебель_в_учреждениях_культуры.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover48.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "8",
    part: ["Методические материалы"],
    title: "Оформление досок объявлений",
    description: [
      "В некоторых учреждениях культуры доски объявлений — самый важный информационный носитель. Но как увеличить их читаемость? Эта глава сборника «Рекомендации по дизайну для учреждений культуры» рассказывает о проектировании, изготовлении и размещении таких досок в вестибюлях и коридорах учреждений культуры.",
    ],
    theme: ["Ремонты в учреждениях культуры", "Стандарты коммуникации: дизайн"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 71,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/оформление_досок_объявлений.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover49.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "9",
    part: ["Методические материалы"],
    title: "Раздаточные материалы",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» описывает типы раздаточных материалов и технический подход к их производству. Глава рассчитана на сотрудников учреждений культуры, которым, в силу их ежедневных обязанностей, приходится иметь дело с типографиями и дизайнерами полиграфической продукции.",
    ],
    theme: ["Стандарты коммуникации: дизайн"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 72,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/раздаточные_материалы.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover50.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "10",
    part: ["Методические материалы"],
    title: "Объявления в учреждениях культуры",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» рассказывает о повседневных объявлениях, которые можно увидеть в учреждениях культуры. Даются рекомендации по составлению, применению и верстке объявлений.",
    ],
    theme: ["Стандарты коммуникации: дизайн"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 73,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/объявления_в_учреждениях_культуры.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover51.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "11",
    part: ["Методические материалы"],
    title: "Наружная реклама для учреждений культуры",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» рассказывает о возможностях наружной рекламы. Особое внимание уделяется производству и размещению рекламных носителей, которые не испортили бы облик и дух зданий и территорий учреждений культуры.",
    ],
    theme: ["Стандарты коммуникации: дизайн", "Городская среда"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 74,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/наружная_реклама_для_учреждений_культуры.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover52.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "12",
    part: ["Методические материалы"],
    title: "Создание фирменного стиля",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» рассказывает об одном из самых сложных аспектов дизайна: создании фирменных стилей. В тексте раскрывается понятие фирменного стиля и рассказывается о разных подходах к работе с дизайн-студиями.",
    ],
    theme: ["Стандарты коммуникации: дизайн"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 75,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/создание_фирменного_стиля.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover53.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "13",
    part: ["Методические материалы"],
    title:
      "Технические советы по новогоднему оформлению витрин учреждений культуры",
    description: [
      "В этом иллюстрированном документе рассматривается несколько практик новогоднего оформления витрин; рассказывается о том какие декоративные предметы можно использовать, как их изготовить, расставить и осветить. Не претендуя на полноту, документ выстроен очень прагматично: он говорит о ценовых диапазонах оформления, особенностях монтажа объектов и приводит примеры из международной практики.",
    ],
    theme: [
      "Стандарты коммуникации: дизайн",
      "Ремонты в учреждениях культуры",
      "Городская среда",
    ],
    type: ["методическая разработка"],
    actions: [
      {
        id: 86,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/технические_советы_по_новогоднему_оформлению_витрин_учреждений_культуры.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_13.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "14",
    part: ["Методические материалы"],
    title: "С 9 до 6. Оформление табличек с режимом работы",
    description: [
      "Издание рассказывает о методах составления, производства и размещения табличек, содержащих график работы учреждений культуры. Оно предназначено в первую очередь для музеев и выставочных залов Москвы и включает иллюстрированные примеры и рекомендации для различных типов зданий. Оно также может использоваться другими организациями.",
    ],
    theme: ["Стандарты коммуникации: дизайн"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 87,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/с_9_до_6_оформление_табличек_с_режимом_работы.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_14.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "15",
    part: ["Методические материалы"],
    title: "Оформление вывесок",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» рассказывает об оформлении вывесок. Описываются роль вывесок в оформлении фасада, сбор предварительной аналитики, найм дизайнеров и формальные аспекты оформления фасадов. Она также дополняет рекомендации Студии Лебедева, регулирующие все вывески в Москве.",
    ],
    theme: ["Стандарты коммуникации: дизайн", "Городская среда"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 76,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/оформление_вывесок.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover54.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "16",
    part: ["Методические материалы"],
    title: "Оформление витрин",
    description: [
      "Эта глава сборника «Рекомендации по дизайну для учреждений культуры» посвящена оформлению витрин и окон. В ней рассказывается о том, какие бывают витрины, как их минималистичо и дешево оформить, а также о том как заказать оформление дизайнерам и не ошибиться в монтаже.",
    ],
    theme: [
      "Стандарты коммуникации: дизайн",
      "Ремонты в учреждениях культуры",
      "Городская среда",
    ],
    type: ["методическая разработка"],
    actions: [
      {
        id: 77,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/оформление_витрин.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover55.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "17",
    part: ["Исследования"],
    title: "Доклад о культуре мировых городов 2014",
    description: [
      "Доклад представляет собой международный проект по изучению культуры и будущего мировых городов, запущенный по инициативе Мэрии Лондона. В докладе рассматривается ситуация в сфере культуры в крупнейших городах мира. Здесь собраны данные по 60 культурным показателям, приводится оценка как предложения, так и спроса в сфере культуры, рассказывается о концепциях, определяющих курс городов в области культурного развития.",
    ],
    theme: ["Кейсы и статистика городов мира", "Городская среда"],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 88,
        text: "Перевод доклада на русский язык",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/доклад_о_культуре_мировых_городов_2014.pdf",
        },
      },
      {
        id: 89,
        text: "World Cities Culture Report 2014. Original version",
        btn: {
          text: "Открыть PDF",
          url: "https://en.institutparisregion.fr/fileadmin/NewEtudes/site_anglais/KnowHow/The_Role_of_Culture_in_World_Cities/World_Cities_Culture_Report_2014_hires.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_17.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "18",
    part: ["Исследования"],
    title: "Доклад о финансировании культуры в мировых городах",
    description: [
      "В докладе представлены результаты первого глобального сравнительного исследования, посвященного финансированию сферы культуры в мировых городах. Исследование опирается на количественные и качественные данные, предоставленные шестнадцатью городами-участниками Культурного форума мировых городов.",
      "В докладе рассмотрены основные источники финансирования и структура расходов на сферу культуры. Из него вы узнаете о городах-лидерах государственного финансирования с бюджетом более 1 млрд долларов в год, о том, где частное финансирование сферы культуры преобладает над государственным, и об инновационных моделях финансирования культуры.",
    ],
    theme: ["Кейсы и статистика городов мира"],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 53,
        text: "Перевод доклада на русский язык",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/доклад_о_финансировании_культуры_в_мировых_городах.pdf",
        },
      },
      {
        id: 54,
        text: "World Cities Culture Finance Report. Original version",
        btn: {
          text: "Открыть PDF",
          url: "https://en.institutparisregion.fr/fileadmin/NewEtudes/site_anglais/KnowHow/The_Role_of_Culture_in_World_Cities/170516_WCCF_FullReport.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_18.png" },
    year: ["2017"],
    position: 0,
    date_time: "2017-06-13 18:22:00",
  },
  {
    id: "19",
    part: ["Исследования"],
    title: "Доклад о преобразовании городов средствами культуры",
    description: [
      "Доклад посвящен влиянию культуры на горожан и городскую среду. В нем собраны 20 культурных проектов участников Культурного форума мировых городов и описываются основные позитивные изменения, к которым привели эти проекты. Доклад также иллюстрирует разнообразие, масштаб, динамичность культурной жизни мировых городов.",
    ],
    theme: [
      "Кейсы и статистика городов мира",
      "Городская среда",
      "Культурно-массовые мероприятия",
    ],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 78,
        text: "Перевод доклада на русский язык",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/доклад_о_преобразовании_городов_средствами_культуры.pdf",
        },
      },
      {
        id: 79,
        text: "Transformational Cultural Projects Report. Original version",
        btn: {
          text: "Открыть PDF",
          url: "https://socialvalueuk.org/wp-content/uploads/2023/05/Transformational_Cultural_Projects_Report_low.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover56.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "20",
    part: ["Методические материалы"],
    title: "Город, дружелюбный к детям",
    description: [
      "Этот материал содержит обзор концепции Города, дружелюбного к детям (от англ. child friendly city или CFC). Помимо основных принципов концепции, там представлена краткая справка о том, как обстоят дела с ее реализацией в городах России и как учреждения культуры могут сделать города ближе и добрее к самым младшим их жителям. Материал был опубликован Московским институтом социально-культурных программ в2014 году с благодарностью за помощь в подготовке материала Дарье Уткиной, руководителю общественной организации «Город-друг», и культурологу Марии Рикитянской.",
    ],
    theme: ["Кейсы и статистика городов мира", "Городская среда"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 90,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/город_дружелюбный_к_детям.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_20.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "21",
    part: ["Методические материалы"],
    title: "Культурная децентрализация: стратегия и виды",
    description: [
      "Демократизация культуры — тренд последних десятилетий, и на него ориентируются ведущие мегаполисы планеты от Берлина до Нью-Йорка. Этот обзор Московского института социально-культурных программ был подготовлен в 2014 году и обращен к европейскому и северо-американскому опыту.",
    ],
    theme: ["Кейсы и статистика городов мира", "Городская среда"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 91,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/культурная_децентрализация_стратегия_и_виды.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_21.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "22",
    part: ["Методические материалы"],
    title: "Концепция креативного города",
    description: [
      "Культурная политика, как и сама идея о том, что культура может быть объектом управления, возникла в XX в. Хотя нынешние программы культурной политики в разных городах и странах имеют свои особенности, у них немало общего. Одна из ключевых идей, получивших широкое, если не повсеместное, распространение, — это концепция креативного города. В этом материале Московского института социально-культурных программ описаны принципы концепции, основные черты креативного города, недостатки креативных программ и примеры креативных городов.",
    ],
    theme: ["Кейсы и статистика городов мира", "Городская среда"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 92,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/концепция_креативного_города.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_22.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "23",
    part: ["Методические материалы"],
    title: "Культурное разнообразие: политика интеркультурализма",
    description: [
      "Брошюра Московского института социально-культурных программ 2014 года посвящена анализу модели политик культурного разнообразия. Помимо традиционных, отживших моделей особенное внимание уделяется мультикультурализму, который является, с одной стороны, самой распространенной, а с другой – критикуемой в последнее время моделью. Издание разбирает, с чем связана такая двусмысленная ситуация, а также в качестве альтернативной модели описывается концепция интеркультурализма, ее плюсы, минусы, перспективы, а также значение для Москвы.",
    ],
    theme: ["Кейсы и статистика городов мира", "Городская среда"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 93,
        text: "",
        btn: {
          text: "Открыть PDF ",
          url: "https://pro.nekrasovka.ru/files/культурное_разнообразие_политика_интеркультурализма.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_23.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "24",
    part: ["Публикации"],
    title: "Библиотеки помогают бороться с цифровым неравенством",
    description: [
      "11 апреля 2020 года гендиректор Центральной библиотеки им. Н.А. Некрасова Мария Привалова дала большое интервью “Ведомостям” о роли публичных библиотек в современном городе, практиках чтения, любимых проектах и особенностях работы Некрасовки в период самоизоляции.",
    ],
    theme: [
      "Библиотечные кейсы",
      "Аудитория библиотек и практики чтения",
      "Городская среда",
    ],
    type: ["интервью", "СМИ"],
    actions: [
      {
        id: 5,
        text: "",
        btn: {
          text: "Читать материал",
          url: "https://www.vedomosti.ru/lifestyle/characters/2020/04/10/827790-knizhnoi-roli-biblioteki",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover4.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "25",
    part: ["Подкасты"],
    title: "Видеобеседа «Культурный повод» на радио «Культура»",
    description: [
      "11 июня 2020 года программный директор Радио Культура Ксения Ламшина взяла интервью у руководителей Центральной библиотеки им. Н.А. Некрасова — генерального директора Марии Привловой и первого заместителя Алины Богатковой.",
      "Некрасовка, как ее называют, – уникальная библиотека даже внутри Москвы. Для меня самое интересное – это аспект управленческой деятельности команды библиотеки. Показательный и успешный опыт культурного менеджмента. Бережное сохранение фондов, работа с разновозрастной аудиторией, событийная активность. И формирование имиджа, репутации. Но я, например, не знала, что библиотека помогает переделывать библиотеки в регионах. Это большая и любопытная тема, на которую мы поговорим в процессе интервью,” — ведущая Ксения Ламшина.",
    ],
    theme: [
      "Библиотечные кейсы",
      "Аудитория библиотек и практики чтения",
      "Ремонты в учреждениях культуры",
      "Оптимизация и автоматизация процессов",
    ],
    type: ["интервью", "СМИ", "аудио", "видео"],
    actions: [
      {
        id: 6,
        text: "",
        btn: {
          text: "Слушать на сайте радио",
          url: "https://www.cultradio.ru/brand/episode/id/63038/episode_id/2412364/",
        },
      },
      {
        id: 7,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=K3APKP3VFGg",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover5.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "26",
    part: ["Публикации"],
    title: "Почему библиотеки не исчезнут в цифровом мире будущего",
    description: [
      "Колонка Марии Привловой, генерального директора Центральной библиотеки. им. Н.А. Некрасова, об исторической и современной роли библиотек, в том числе о доступе к информации, сочетании разных ресурсов и экспертизы, удобном пространстве, просвещении, досуге и центре сообществ и территорий.",
      "Сейчас, когда любые сведения доступны в Сети, библиотеки кажутся пережитком прошлого. Почему — вопреки распространенному заблуждению — они не исчезнут, и как библиотеки вписываются в современные социальные и образовательные тренды.",
    ],
    theme: ["Аудитория библиотек и практики чтения", "Городская среда"],
    type: ["СМИ", "статья"],
    actions: [
      {
        id: 10,
        text: "",
        btn: {
          text: "Читать материал",
          url: "https://trends.rbc.ru/trends/education/5ec6e3a09a7947ee11d629bd?fbclid=IwAR0x3GpegrjduPumAnBJe3wLb8oMUiTRrPJY6v4dD7RCBspDA9gqAQPz3S8",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover6.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "27",
    part: ["События"],
    title: "Библиотечная конференция «Всё сложно» 2019",
    description: [
      "Библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года в библиотеке им. Н.А. Некрасова. В рамках конференции прошли более 35 часов мастер-классов, дискуссий, презентаций, лекций и тренингов.",
      "Программа конференции была разделена на тематические направления (треки), которые представляют «болевые точки» в повседневной работе библиотеки:",
      "- читательский спрос и комплектование,",
      "- работа с разными аудиториями,",
      "- стандарты коммуникации с посетителями,",
      "- поиск средств для реализации проектов,",
      "- оптимизация и автоматизация работы.",
      "Открывала конференцию дискуссия об экономике литературы с участием Ая Эн, Олега Лекманова, Романа Сенчина и Александра Снегирёва, а закрывала — премьера вербатим-пьесы о жизни библиотеки и библиотекарей «Библиотека имени меня» (драматург Нина Беленицкая, режиссер Юрий Шехватов).",
      "На конференцию съехались более 200 человек из 17 регионов России. Перед участниками конференции выступили более 20 экспертов библиотечного дела, книжного бизнеса, образования, культуры, благотворительности.",
    ],
    theme: [
      "Стандарты коммуникации: общение",
      "Стандарты коммуникации: дизайн",
      "Целевые аудитории учреждений культуры",
      "Аудитория библиотек и практики чтения",
      "Комплектование библиотечных фондов",
      "Библиотечные кейсы",
      "Поиск средств",
      "Оптимизация и автоматизация процессов",
    ],
    type: ["семинар", "конференция", "видео", "фото", "все сложно"],
    actions: [
      {
        id: 28,
        text: "Смотрите краткий репортаж с конференции.",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=uWc30nNinY8&feature=emb_logo",
        },
      },
      {
        id: 29,
        text: "Смотрите полные видеозаписи дискуссий, презентаций и лекций на сайте конференции.",
        btn: {
          text: "Открыть сайт",
          url: "https://biblioteka.nekrasovka.ru/conference2019",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-27.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "28",
    part: ["События"],
    title:
      "Публичное интервью с Германном Хорстом (Herman Horst), директором библиотеки LocHal Tilburg",
    description: [
      "Запись публичного интервью с Германном Хорстом, директором библиотеки Среднего Брабанта, центральная площадка которой находится в знаменитом культурном центре LocHal в Тилбурге (Нидерланды). Герман Хорст рассказывает об одной из самых современных библиотек мира, ее проектах, ключевых показателях эффективностях, бюджете, штате и работе с волонтерами.",
      "Интервью состоялось с 5 февраля 2020 года с участием представителей библиотечного сообщества Москвы. После презентации Герман Хорст ответил на вопросы участников.",
    ],
    theme: ["Библиотечные кейсы"],
    type: ["семинар", "интервью", "видео"],
    actions: [
      {
        id: 11,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=VHWyPG4GF1s&feature=youtu.be",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover7.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "29",
    part: ["Образовательные программы"],
    title: "Курс повышения квалификации «Коммуникация с читателями»",
    description: [
      "Курс позволяет слушателям освоить:",
      "- ключевые принципы успешного взаимодействия с читателями,",
      "- визуальные и вербальные коммуникации библиотеки,",
      "- влияние корпоративной культуры на внешние коммуникации,",
      "- навыки работы с особыми аудиториями,",
      "- правила успешного общения в социальных сетях и работы с негативом,",
      "- правила делового общения по телефону и электронной почте,",
      "- оптимальные алгоритмы действий в повседневных и конфликтных ситуациях.",
      "Курс ориентирован в первую очередь на сотрудников, занятых в библиотечном обслуживании, и руководителей библиотек.",
    ],
    theme: [
      "Стандарты коммуникации: общение",
      "Стандарты коммуникации: дизайн",
      "Оптимизация и автоматизация процессов",
    ],
    type: ["повышение квалификации"],
    actions: [
      {
        id: 1,
        text: "Подробнее о программе и условиях участия можно посмотреть на сайте курса.",
        btn: {
          text: "Открыть сайт",
          url: "https://biblioteka.nekrasovka.ru/edu/communications",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover1.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "30",
    part: ["Исследования"],
    title: "Доклады о культуре мировых городов 2015",
    description: [
      "Доклад о культуре мировых города — международный проект по изучению культуры глобальных городов мира, включая механизмы реализации культурной политики и влияние культуры на развитие городов. Доклад готовится ежегодно Культурным форумом мировых городов. В докладе 2015 года рассматривается ситуация в сфере культуры в 29 городах-участниках форума. Для доклада были проведены 150 интервью с представителями разных отраслей, лидерами общественного мнения в каждом из городов.",
    ],
    theme: ["Кейсы и статистика городов мира", "Городская среда"],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 80,
        text: "Перевод доклада на русский язык",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/доклады_о_культуре_мировых_городов_2015.pdf",
        },
      },
      {
        id: 81,
        text: "World Cities Culture Report 2015. Original version",
        btn: {
          text: "Открыть PDF",
          url: "https://en.institutparisregion.fr/fileadmin/NewEtudes/site_anglais/KnowHow/The_Role_of_Culture_in_World_Cities/WCCF_Report_D2.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover57.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "31",
    part: ["Подкасты"],
    title: "«Встань москвичом»: новая жизнь библиотек",
    description: [
      "Мария Привалова, генеральный директор Центральной библиотеки. им. Н.А. Некрасова рассказала слушателям радиостанции «Москва FM» о новой жизни библиотек. В гостях у программы «Встань москвичом».",
    ],
    theme: ["Аудитория библиотек и практики чтения"],
    type: ["СМИ", "аудио"],
    actions: [
      {
        id: 45,
        text: "",
        btn: {
          text: "Слушать на сайте радио",
          url: "https://www.m24.ru/audios/Moskva-FM/25042018/136955",
        },
      },
      {
        id: 46,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=L1u4LFnRVa0",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_31.png" },
    year: ["2018"],
    position: 0,
    date_time: "2018-06-13 18:22:00",
  },
  {
    id: "32",
    part: ["Подкасты"],
    title:
      "«Основной состав»: спецвыпуск с Марией Приваловой и Олегом Жаденовым (№ 50)",
    description: [
      "8 мая 2018 года вышел в эфир очередной выпуск совместного проекта Государственного музея истории российской литературы имени В.И. Даля и радио «Культура» — программы «Основной состав: люди и события Государственного литературного музея».",
      "Тема специального выпуска программы – московские библиотеки сегодня: точки роста. Гости выпуска: Мария Привалова, генеральный директор Центральной универсальной научной библиотеки им. Н.А. Некрасова и Олег Жадёнов, генеральный директор Централизованной библиотечной системы Восточного административного округа г. Москвы. Ведущий программы — Дмитрий Бак, филолог, литературный критик, директор Государственного музея истории российской литературы имени В.И. Даля.",
    ],
    theme: ["Аудитория библиотек и практики чтения", "Городская среда"],
    type: ["СМИ", "видео"],
    actions: [
      {
        id: 47,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=QensPIKU22A&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_32.png" },
    year: ["2018"],
    position: 0,
    date_time: "2018-06-13 18:22:00",
  },
  {
    id: "33",
    part: ["Публикации"],
    title: "Место силы: библиотека имени Н.А. Некрасова",
    description: [
      "Публикация Агентства стратегических инициатив о том, как самая большая публичная библиотека Москвы стала «убежищем» для подростков, пришла в парки и торговые центры и решила поддерживать некоммерческие инициативы.",
    ],
    theme: ["Аудитория библиотек и практики чтения", "Библиотечные кейсы"],
    type: ["СМИ", "статья"],
    actions: [
      {
        id: 48,
        text: "",
        btn: {
          text: "Читать материал",
          url: "https://www.asi.org.ru/2018/07/31/mesto-sily-nekrasovka/",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_33.png" },
    year: ["2018"],
    position: 0,
    date_time: "2018-06-13 18:22:00",
  },
  {
    id: "34",
    part: ["Подкасты"],
    title: "Интервью Марии Приваловой телеканалу ОТР",
    description: [
      "ОТР встретили с Марией Привловой, генеральным директором библиотеки им. Н.А. Некрасова, чтобы поговорить о том, почему в эпоху электронных книг и интернета люди продолжают ходить в библиотеки.",
      "Что же такое библиотека в XXI веке? Это по-прежнему хранилище знаний или клуб по интересам? Живы ли библиотеки, востребованы ли?",
    ],
    theme: ["Аудитория библиотек и практики чтения"],
    type: ["СМИ", "видео"],
    actions: [
      {
        id: 55,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://otr-online.ru/programmy/bolshoe-intervyu/mariya-privalova-26455.html",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_34.png" },
    year: ["2017"],
    position: 0,
    date_time: "2017-06-13 18:22:00",
  },
  {
    id: "35",
    part: ["Исследования"],
    title: "Доклад о культуре мировых городов 2018",
    description: [
      "Доклад о культуре мировых городов — это результат международного проекта по изучению культуры глобальных городов мира, включая механизмы реализации культурной политики и влияние культуры на развитие городов.",
      "Доклад подготовлен Культурным форумом мировых городов, куда входят 40 самых ярких культурных столиц мира, в том числе Москва. В нем рассматривается ситуация в сфере культуры в 35 городах-участниках форума: инновационные программы и решения, а также ключевые тенденции и инфраструктурные проекты, которые реализуются в этих городах.",
    ],
    theme: [
      "Кейсы и статистика городов мира",
      "Культурно-массовые мероприятия",
      "Городская среда",
    ],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 43,
        text: "Перевод доклада на русский язык",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/культура_мировых_городов_доклад_2018.pdf",
        },
      },
      {
        id: 44,
        text: "World Cities Culture Report 2018. Original version",
        btn: {
          text: "Открыть PDF",
          url: "https://worldcitiescultureforum.com/wp-content/uploads/2023/09/WCCF-Report_2018.pdf ",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_35.png" },
    year: ["2018", "2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "36",
    part: ["Методические материалы"],
    title: "Регламент внестационарного обслуживания",
    description: [
      "Почему так много людей в России перестали ходить в библиотеки? Причин много, и одна из них — потеря привычки. Пока в библиотеках плохо обновлялись фонды, устаревали пространства и форматы обслуживания, читатели забыли в них дорогу. Мы видим обслуживание вне стационара — в многолюдных местах — как способ вернуть библиотеки в повседневные маршруты горожан.",
      "С этой целью мы открываем пункты книговыдачи в торговых центрах (ТРЦ «Океания» и ТЦ «Водный»), в парках (Сад им. Баумана и Парк искусств «Музеон») и даже на рынке (Даниловский), а еще на фестивалях (Фестиваль креативных индустрий) и выставках (Павильон № 57 на ВДНХ) и на других площадках.",
      "Кроме того, внестационар позволяет библиотекам обслуживать тех читателей, у которых нет возможности прийти в библиотеку.",
      "Чтобы не возникало сомнений в том, какая работа может считаться внестационарным обслуживанием, мы разработали проект регламента этого вида библиотечно-информационного обслуживания. Библиотеки могут адаптировать его для себя.",
    ],
    theme: ["Библиотечные кейсы"],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 30,
        text: "",
        btn: {
          text: "Скачать DOCX",
          url: "https://nekrasovka.ru/file/1613830639.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover3.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "37",
    part: ["Методические материалы"],
    title:
      "Библиотечно-информационное обслуживание посетителей с инвалидностью",
    description: [
      "Говоря о доступности библиотек и услуг, которые они предоставляют, для людей с инвалидностью, как правило, в первую очередь имеют в виду адаптацию здания и пространства библиотеки согласно актуальным стандартам физической доступности, а также приобретение специального оборудования и программного обеспечения, удаленное обслуживание и адаптацию отдельных сервисов.",
      "Однако язык, открытая и понятная коммуникация и навыки ситуационной помощи значат не меньше для обеспечения комфортной и безопасной среды для посетителей с особыми потребностями.",
      "Документ, который мы разработали в первую очередь для обучения сотрудников отдела библиотечно-информационного обслуживания, дает общие представления о принципах доступности, социальной модели инвалидности, понятии и принципах универсального дизайна. Он помогает понять, как корректно говорить о людях с инвалидностью и обращаться к ним, в том числе он содержит правила этикета, составленные людьми с инвалидностью, а также базовые рекомендации для взаимодействия и ситуационной помощи посетителям с различными видами инвалидности.",
    ],
    theme: [
      "Целевые аудитории учреждений культуры",
      "Стандарты коммуникации: общение",
      "Стандарты коммуникации: дизайн",
    ],
    type: ["методическая разработка"],
    actions: [
      {
        id: 31,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/обслуживание_посетителей_инвалидность.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-37.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "38",
    part: ["Методические материалы"],
    title:
      "Привлечение добровольцев к работе в библиотеке: типовой договор о сотрудничестве",
    description: [
      "Во всем мире волонтеры — важный ресурс работы и развития учреждений культуры. У наиболее успешных институций штат волонтеров превосходит количество персонала.",
      "Мы тоже стремимся развивать работу с волонтерами. На крупные мероприятия нам удается привлечь нескольких десятков человек, и мы очень ценим их помощь.",
      "Сотрудничество волонтеров (добровольцев) с организацией должно оформляться документально. Мы разработали типовой договор, который вы можете использовать, чтобы участие волонтеров в работе вашего учреждения соответствовало всем юридическим требованиям.",
    ],
    theme: ["Целевые аудитории учреждений культуры"],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 32,
        text: "",
        btn: {
          text: "Скачать DOCX",
          url: "https://nekrasovka.ru/file/1582318964.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover3.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "39",
    part: ["Методические материалы"],
    title: "Рекомендации по расстановке фонда открытого доступа",
    description: [
      "Открытые фонды — это современный подход к организации библиотечных пространств. Мы уверены, что публичные библиотеки должны стремиться к тому, чтобы максимальное количество их фондов было в открытом доступе. В то же время, когда читатели постоянно взаимодействуют с книгами и журналами, поддерживать порядок и привлекательный вид книжных полок не так-то просто.",
      "Качество расстановки фондов открытого доступа существенно влияет как на книговыдачу, так и на атмосферу в пространстве библиотеки, поэтому мы создали этот документ для себя и других библиотек — чтобы помочь разработке и внедрению стандартов расстановки библиотечных фондов.",
    ],
    theme: ["Стандарты коммуникации: дизайн"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 33,
        text: "Первая версия этого издания вышла в 2019 году. Три года спустя мы во многом уточнили и дополнили его.",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/рекомендации_по_расстановке_фонда.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-39.png" },
    year: ["2022"],
    position: 0,
    date_time: "2022-09-20 10:51:19",
  },
  {
    id: "40",
    part: ["Исследования"],
    title:
      "Создание пространств для сферы культуры: доклад Культурного форума мировых городов",
    description: [
      "Перед вами доклад Культурного форума мировых городов «Инструментарий по созданию пространств для сферы культуры». Он содержит 17 различных инструментов поддержки и сохранения культурных и творческих пространств, которые используются в разных городах мира.",
      "Инструменты разделены на три блока: проектирование и стратегии, субсидирование и финансы, а также развитие городских территорий и обеспечение новыми пространствами.",
      "Будучи задуман как централизованный источник информации для ответственных лиц, этот набор инструментов иллюстрирует варианты стратегий, которые испытаны и проверены. Анализ основан на методах, уже опробованных участниками форума, их опыте и экспертизе.",
      "Это гибкое руководство, позволяющее каждому городу использовать наиболее полезные и актуальные для его развития инструменты.",
    ],
    theme: ["Кейсы и статистика городов мира", "Городская среда"],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 12,
        text: "Перевод доклада на русский язык",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/создание_пространств_культуры.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover8.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "41",
    part: ["Методические материалы"],
    title:
      "Электронные ресурсы и онлайн-медиа: навигатор для библиотекарей 2022",
    description: [
      "Будучи частью организации, которая работает с информацией и занимается просвещением, библиотекарь должен быть эрудированным и начитанным специалистом. Для читателя библиотекарь — главный источник информации о других надежных ресурсах ее получения.",
      "Задача библиотекаря — дать точный и полный ответ на запрос читателя, а также предоставить ему дополнительную информацию, которая может быть ему полезна и интересна. Для этого нужно не только профессионально ориентироваться в основных фондах библиотеки, а также в электронных ресурсах, доступ к которым она предоставляет, но и постоянно следить за актуальной повесткой и знакомиться с новыми источниками информации не только о литературе, но и о культуре в целом.",
      "Мы создали этот навигатор, чтобы библиотекарям было легче ориентироваться в современных электронных ресурсах и онлайн-медиа. Мы надеемся, что он поможет лучше удовлетворять запросам читателей и будет полезен коллегам для собственного профессионального развития.",
      "Первая версия этого издания вышла в 2020 году. С тех пор мы обновляем его ежегодно.",
    ],
    theme: [
      "Стандарты коммуникации: общение",
      "Аудитория библиотек и практики чтения",
    ],
    type: ["методическая разработка"],
    actions: [
      {
        id: 13,
        text: "",
        btn: {
          text: "Открыть сайт",
          url: "https://online-navigator.nekrasovka.ru",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover9.png" },
    year: ["2022"],
    position: 0,
    date_time: "2022-07-26 10:51:19",
  },
  {
    id: "42",
    part: ["Исследования"],
    title: "Детское и юношеское чтение: исследование и рекомендации",
    description: [
      "В 2019–2020 годах мы провели масштабное исследование аудитории детских библиотек Москвы — детей и их родителей. Дополнительно были изучены кейсы успешных детских библиотек в российских и зарубежных библиотеках.",
      "Результатом стал подробный отчет о запросе аудитории к фондам детских библиотек, а также к их пространствам, мероприятиям и другим услугам. Отдельное внимание уделено практикам чтения детей и подростков.",
      "Финальная глава посвящена основанным на исследовании рекомендациям для детских библиотек — по самым разным аспектам их деятельности.",
    ],
    theme: [
      "Аудитория библиотек и практики чтения",
      "Стандарты коммуникации: общение",
      "Целевые аудитории учреждений культуры",
      "Комплектование библиотечных фондов",
      "Библиотечные кейсы",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 14,
        text: "Обратите внимание, что для вашего удобства в файле сделано интерактивное оглавление.",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/детское_юношеское_чтение.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover10.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "43",
    part: ["Исследования"],
    title: "Библионочь 2018. Оценка эффективности акции",
    description: [
      "«Библионочь» — ежегодная культурная акция, которая проходит в апреле в разных городах России с 2012 года. В 2018 году мы изучили аудиторию «Библионочи» в Москве с точки зрения двух значимых целей, которые должна преследовать библиотечная акция:",
      "1. Развивать аудиторию библиотек, привлекать потенциальных посетителей, сделать их возвратной и лояльной аудиторией.",
      "2. Помогать библиотекам продвигать литературу и чтение, тем самым выступая инструментом реализации непосредственных задач публичной библиотеки.",
      "Говоря об эффективности «Библионочи» 2018 года в Москве, мы отталкиваемся от этих двух целей и анализируем то, насколько акции удалось их реализовать.",
    ],
    theme: [
      "Культурно-массовые мероприятия",
      "Целевые аудитории учреждений культуры",
      "Аудитория библиотек и практики чтения",
      "Библиотечные кейсы",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 49,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/библионочь_2018_оценка_эффективности_акции.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_43.png" },
    year: ["2018"],
    position: 0,
    date_time: "2018-06-13 18:22:00",
  },
  {
    id: "44",
    part: ["Исследования"],
    title: "Ночь нужна. Исследование аудитории «Ночи в музее» 2018",
    description: [
      "Отчет посвящен анализу аудитории и форматам посещения культурной акции «Ночь в музее», ее успехам и проблемам. На основе данных исследования авторы предлагают рекомендации организаторам — отдельным музеям и на уровне города, — руководствуясь которыми можно проводить акцию более эффективно.",
    ],
    theme: [
      "Культурно-массовые мероприятия",
      "Целевые аудитории учреждений культуры",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 50,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/ночь_нужна_исследование_аудитории_ночи_в_музее_2018.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_44.png" },
    year: ["2018"],
    position: 0,
    date_time: "2018-06-13 18:22:00",
  },
  {
    id: "45",
    part: ["Исследования"],
    title: "Это сделает День. Факторы успеха московского Дня города",
    description: [
      "Этот отчет — результат пятилетнего мониторинга аудитории московского Дня города. В нем собраны самые важные данные об аудитории этого главного праздника Москвы с 2013 по 2017 года, разобраны примеры лучших мероприятий и предложены идеи для его дальнейшего развития.",
    ],
    theme: [
      "Культурно-массовые мероприятия",
      "Целевые аудитории учреждений культуры",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 52,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/это_сделает_день_факторы_успеха_московского_дня_города.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_45.png" },
    year: ["2017", "2018"],
    position: 0,
    date_time: "2018-06-13 18:22:00",
  },
  {
    id: "46",
    part: ["Исследования"],
    title: "Какие ваши годы. Досуг москвичей старшего возраста",
    description: [
      "Это третья, завершающая, часть исследования возрастных групп, наименее вовлеченных в культурную жизнь Москвы. Она посвящена москвичам пенсионного возраста.",
      "Как старение сказывается на досуге москвичей? Как досуговые практики влияют на качество жизни людей старшего возраста? Как пожилые москвичи проводят свое свободное время? И почему именно так, а не иначе? Какие мотивации распространены среди людей третьего возраста? Как учреждения культуры могут повлиять на досуг людей 55+ и зачем им это нужно? Ответы на эти вопросы вы найдете в опубликованном отчете. А если читать внимательно — там еще много интересного.",
      "Вы можете посмотреть отчет в интерактивной верстке или скачать pdf-версию.",
    ],
    theme: ["Целевые аудитории учреждений культуры"],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 59,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/какие_ваши_годы_досуг_москвичей_старшего_возраста.pdf",
        },
      },
      {
        id: 60,
        text: "",
        btn: { text: "Открыть сайт", url: "http://assets.miscp.ru/third-age" },
      },
      {
        id: 61,
        text: "Age Is Just a Number. The Leisure Time Activities of Moscow’s Senior Citizens. ",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/age_is_just_a_number_the_leisure_time_activities_of_moscow_s_senior_citizens.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_46.png" },
    year: ["2015", "2018"],
    position: 0,
    date_time: "2018-06-13 18:22:00",
  },
  {
    id: "47",
    part: ["Исследования"],
    title: "Как проводят время москвичи среднего возраста",
    description: [
      "Это вторая часть исследования возрастных групп, наименее вовлеченных в культурную жизнь Москвы. Он посвящен москвичам в возрасте от 35​ ​до 54​ ​лет.",
      "Люди среднего возраста, в отличие от детей, подростков, молодежи и старшего поколения, почти никогда не выделяются исследователями в качестве отдельной социальной группы. К среднему возрасту людей относят по остаточному принципу — это взрослый человек, который не входит ни в одну из других социально-возрастных групп.",
      "Представленное исследование исправляет эту несправедливость. Здесь вы найдете анализ свободного времени и​менно представителей среднего возраста и рекомендации по работе с ними.",
      "Вы можете посмотреть отчет в интерактивной верстке или скачать pdf-версию. А для хорошего настроения мы создали набор стикеров для мессенджера Telegram с цитатами наших респондентов.",
    ],
    theme: ["Целевые аудитории учреждений культуры"],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 62,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/как_проводят_время_москвичи_среднего_возраста.pdf",
        },
      },
      {
        id: 63,
        text: "",
        btn: { text: "Открыть сайт", url: "http://assets.miscp.ru/middle-age" },
      },
      {
        id: 64,
        text: "",
        btn: {
          text: "Открыть стикеры в Telegram",
          url: "https://t.me/addstickers/miscp",
        },
      },
      {
        id: 65,
        text: "The Leisure Time Activities of Moscow’s Middle-Aged Citizens. This research is devoted to people aged from 35 to 54. It looks at how they organize their free time and also makes recommendations to cultural institutions of practical solutions for working with this age group.",
        btn: {
          text: "Downlaod PDF",
          url: "https://pro.nekrasovka.ru/files/the_leisure_time_activities_of_moscow_s_middle_aged_citizens.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover45.png" },
    year: ["2015", "2017"],
    position: 0,
    date_time: "2017-06-13 18:22:00",
  },
  {
    id: "48",
    part: ["Исследования"],
    title: "Свободное время московских подростков",
    description: [
      "Этот отчет — первая часть исследования возрастных групп, наименее вовлеченных в культурную жизнь Москвы. Он посвящен москвичам в возрасте от 13 до 18 лет.",
      "Подростки не так часто оказывается объектом социологического исследования. В этом отчете рассмотрены досуговые практики московских подростков в контексте больших социальных структур — в частности, школы, семьи и города.",
      "Результаты исследования помогли узнать, как подростки предпочитают проводить свободное время, какие каналы коммуникации наиболее эффективны, и наконец, каково их отношение к учреждениям культуры.",
      "Вы можете посмотреть отчет в интерактивной верстке или скачать pdf-версию.",
    ],
    theme: ["Целевые аудитории учреждений культуры"],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 66,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/свободное_время_московских_подростков.pdf",
        },
      },
      {
        id: 67,
        text: "",
        btn: { text: "Открыть сайт", url: "http://assets.miscp.ru/teens" },
      },
      {
        id: 68,
        text: "The Leisure Time Activities of Moscow’s Teenagers. This research allows us to see the teenagers’ preferences in leisure activities, identify their main communication channels, and learn what their attitude is towards various cultural institutions.",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/the_leisure_time_activities_of_moscow_s_teenagers.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover46.png" },
    year: ["2015", "2016"],
    position: 0,
    date_time: "2016-06-13 18:22:00",
  },
  {
    id: "49",
    part: ["Исследования"],
    title: "Библиотеки Москвы и практики чтения москвичей",
    description: [
      "Что и как часто читают москвичи? Где они берут книги? Ходят ли они в библиотеки? Какой может быть роль библиотеки в  современном городе? В отчете представлены результаты исследований 2015–2016 годов, которые отвечают на эти и многие другие вопросы.",
      "Этот исследовательский проект позволил сформировать реалистичный взгляд на аудиторию библиотек и прийти к выводу, что их недостаточная востребованность в современной Москве — лишь временный сбой. Это исследование изменит ваше мнение о роли библиотек в жизни горожан и потенциале их развития.",
    ],
    theme: [
      "Целевые аудитории учреждений культуры",
      "Аудитория библиотек и практики чтения",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 57,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/библиотеки_москвы_и_практики_чтения_москвичей.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_49.png" },
    year: ["2016", "2017"],
    position: 0,
    date_time: "2017-06-13 18:22:00",
  },
  {
    id: "50",
    part: ["Исследования"],
    title: "«Ночная» жизнь Москвы 2016",
    description: [
      "Этот отчет посвящен аудитории «Ночи в музее — 2016», «Библионочи— 2016» и «Ночи искусств — 2015» в Москве. В нем освещены вопросы, которые наиболее важны для проектирования и проведения культурных акций:",
      "– кто сейчас составляет актуальную аудиторию этих акций и с какими группами важнее всего работать как с потенциальной аудиторией;",
      "– откуда посетители узнают о «Ночах» и как наиболее эффективно строить информационную кампанию;",
      "– что мотивирует людей посещать «Ночи» и как мотивация влияет на формат посещения;",
      "– c какими проблемами сталкиваются посетители и как это отражается на их лояльности «Ночам» как культурной акции в целом и отдельным учреждениям культуры;",
      "– как учреждения культуры могут использовать «Ночи», чтобы развивать свою аудиторию.",
    ],
    theme: [
      "Культурно-массовые мероприятия",
      "Целевые аудитории учреждений культуры",
      "Аудитория библиотек и практики чтения",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 58,
        text: "",
        btn: { text: "Открыть сайт", url: "http://assets.miscp.ru/nights2016" },
      },
    ],
    image: { url: "Nekrasovka_pro_50.png" },
    year: ["2016"],
    position: 0,
    date_time: "2016-06-13 18:22:00",
  },
  {
    id: "51",
    part: ["Исследования"],
    title:
      "Аудитория самого популярного московского праздника. День города 2015",
    description: [
      "Этот отчет посвящен социологическому исследованию московского Дня города. Основное внимание уделено оценке успешности праздничных мероприятий, отношению москвичей к празднику и отдельным площадкам, а также сложностям, с которыми сталкивались посетители в День города.",
    ],
    theme: [
      "Культурно-массовые мероприятия",
      "Целевые аудитории учреждений культуры",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 82,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/аудитория_самого_популярного_московского_праздника_день_города_2015.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover58.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "52",
    part: ["Исследования"],
    title:
      "Успех и будущее городских культурных акций: «Библионочь» и «Ночь в музее» 2015",
    description: [
      "Это отчет об исследовании двух культурных акций 2015 года — «Библионочи» и «Ночи в музее». Первая из них состоялась в Москве в четвертый раз, 24 апреля. Вторая — в девятый, 16 мая. Исследова­ние сфокусировано на профильных для каждой акции учреждениях — библиотеках и музеях соотвественно. В отчете предпринята попытка оценить успешность «Библионочи» и «Ночи в музее», разо­брать основные проблемы акций и стратегические вопросы их развития.",
    ],
    theme: [
      "Культурно-массовые мероприятия",
      "Целевые аудитории учреждений культуры",
      "Аудитория библиотек и практики чтения",
    ],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 83,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/успех_и_будущее_городских_культурных_акций_библионочь_и_ночь_в_музее_2015.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover59.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "53",
    part: ["Исследования"],
    title: "Механика Москвы. Исследование городской среды 2015",
    description: [
      "Этот атлас представляет методологию и основные результаты исследования городской среды «Механика Москвы». В нем проанализирован уровень развития всех муниципальных округов Москвы и выделены группы районов, близких по типу городской среды. По количеству анализируемых социологических и статистических данных о Москве это исследование уникально. Результаты анализа позволяют оценивать диспропорции городского развития и формулировать приоритеты для работы с разными территориями столицы.",
    ],
    theme: ["Городская среда", "Целевые аудитории учреждений культуры"],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 84,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/механика_москвы_исследование_городской_среды_2015.pdf",
        },
      },
      {
        id: 85,
        text: "The Mechanics of Moscow. Research into an urban environment represents an attempt to analyze the level of development of all of Moscow’s municipal districts and categorize them into determined groups of districts with similar urban environments. In terms of the quantity and detail of sociological and statistical data about Moscow analyzed, this research is unique. The results of this analysis allow for the evaluation of inequalities in development between the various districts. It can also be used to support the formulation of priorities for working with different parts of Moscow.",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/the_mechanics_of_moscow_2015.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover60.png" },
    year: ["2015"],
    position: 0,
    date_time: "2015-06-13 18:22:00",
  },
  {
    id: "54",
    part: ["Исследования"],
    title: "Двадцать успешных практик интеграции мигрантов",
    description: [
      "Брошюра посвящена проблеме интеграции мигрантов. Этот процесс неизбежно связан с рядом вопросов. На каких основаниях должно произойти включение мигрантов в новое общество? Каким должен быть конечный результат этого процесса? Какими способами его можно достичь? В издании приведен взгляд на интеграцию, который обобщает западный опыт исследований и практической деятельности, а также описаны двадцать успешных практик, которые были реализованы в разных странах для интеграции мигрантов.",
    ],
    theme: ["Целевые аудитории учреждений культуры", "Городская среда"],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 94,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/двадцать_успешных_практик_интеграции_мигрантов.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_54.png" },
    year: ["2014"],
    position: 0,
    date_time: "2014-06-13 18:22:00",
  },
  {
    id: "55",
    part: ["События"],
    title: "Оптимизация процессов управления и коммуникации внутри учреждения",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции состоялась дискуссия руководителей библиотек с представителями бизнеса об оптимизации процессов управления, коммуникации внутри учреждения и мотивации сотрудников.",
      "Приглашенные эксперты:",
      "— Сергей Никулин, директор компании IDlogic,",
      "— Денис Кузнецов, эксперт проектных продаж.",
      "Модератор: Алина Богаткова, первый заместитель генерального директора библиотеки им. Н.А. Некрасова.",
    ],
    theme: [
      "Оптимизация и автоматизация процессов",
      "Стандарты коммуникации: общение",
    ],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 34,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?time_continue=1&v=b5TFs99lnTs&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-55.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "56",
    part: ["События"],
    title: "Автоматизация библиотечной работы",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции состоялась дискуссия руководителей библиотек с директором компании IDLogic Сергеем Никулиным значение автоматизации для оптимизации работы. После этого были представлены кейсы автоматизации библиотечных процессов из разных библиотек России:",
      "«Интерактивный календарь мероприятий». Егор Бегеза, заведующий отделом автоматизации Государственной библиотеки Югры.",
      "«Агрегатор ЭБС: единое окно поиска». Наталья Головина, заведующая отделом информационно-библиографического сопровождения учебной и научной деятельности библиотеки Университета им. О.Е. Кутафина. Алексей Галкин, библиотекарь 1-й категории библиотеки Университета им. О.Е. Кутафина.",
      "«Чат-бот научной библиотеки ТГУ». Андрей Пархоменко, менеджер IT-проектов научной библиотеки Томского государственного университета.",
    ],
    theme: ["Оптимизация и автоматизация процессов"],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 35,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=H67YHGTITrs&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-56.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "57",
    part: ["События"],
    title: "Как понимать и формировать читательский спрос",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции состоялась дискуссия о читательском спросе.",
      "Максим Мамлыга, книжный обозреватель Esquire и сотрудник книжного магазина 'Подписные издания' о том, почему книжные обзоры стали неотъемлемой частью российского глянца и как Бродский оказался на обложке журнала Esquire.",
      "Юлия Сергеева, старший категорийный менеджер «Книги» (ООО «Республика») о том, почему в сети магазинов Республика не найти лженауки и сентиментального романа.",
      "Алексей Докучаев, директор по маркетингу и контенту Bookmate и основатель и генеральный директор издательств Individuum и Popcorn Books, о том, как устроена автоматически генерируемая система рекомендаций Bookmate и как можно собирать данные о читателях. И о многом другом.",
    ],
    theme: [
      "Комплектование библиотечных фондов",
      "Аудитория библиотек и практики чтения",
    ],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 36,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=yMTLMoZCFJo&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-57.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "58",
    part: ["События"],
    title: "Фандрайзинг в России",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции выступила Регина Сафина, специалист по фандрайзингу в культурной сфере («Гоголь-центр», музей «Гараж», ярмарка современного искусства Cosmoscow и др. проекты).",
    ],
    theme: ["Поиск средств"],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 37,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=nJ95V8RGroA&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-58.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "59",
    part: ["События"],
    title: "Финансирование и продвижение проектов",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции выступила Василина Дрогичинская, директор по развитию краудфандинговой платформы Planeta.ru.",
    ],
    theme: ["Поиск средств"],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 38,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=2CmGd5B8Rx8&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-59.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "60",
    part: ["События"],
    title:
      "Доклады о библиотечных проектах, реализованных на привлеченные средства",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции участники из разных библиотек России рассказали о проектах, реализованных на привлеченные средства:",
      "— Летняя школа «В Добролюбовке снимается кино». Елена Зубова, заведующая отделом литературы по искусству Архангельской областной научной библиотеки им. Н.А. Добролюбова.",
      "— «Хмельная библиотека» VS «Общедоступная библиотека». Елена Кайгородова, директор Библиотечной информационной системы г. Рубцовска Алтайского края.",
      "— #БиблиоStartUp. Анна Кастильо Мехиа, главный библиотекарь библиотеки им. И.А. Наговицына ЦБС г. Ижевска.",
      "— Деловые игры библиотеки и НКО. Юлия Ким, заместитель директора по автоматизации Государственной библиотеки Югры.",
    ],
    theme: ["Поиск средств", "Библиотечные кейсы"],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 39,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=ggUf4qTg3g8&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-60.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "61",
    part: ["События"],
    title:
      "Особенности взаимодействия с детьми с ментальными нарушениями развития",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции эксперты говорили о работе с аудиториями, которые требуют специальных знаний и навыков: люди с ОИР, мигранты и бездомные.",
      "Юлия Ахтямова, психолог, педагог Центра лечебной педагогики, рассказала об особенностях взаимодействия с детьми с ментальными нарушениями развития.",
    ],
    theme: [
      "Целевые аудитории учреждений культуры",
      "Стандарты коммуникации: общение",
    ],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 40,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=rjNzPyojRWs&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-61.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "62",
    part: ["События"],
    title: "Миграция и культурное производство",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции эксперты говорили о работе с аудиториями, которые требуют специальных знаний и навыков: люди с ОИР, мигранты и бездомные.",
      "Ярослав Алёшин, куратор социально-культурных проектов фонда V-A-C, рассказал о миграции и культурном производстве.",
    ],
    theme: ["Целевые аудитории учреждений культуры", "Городская среда"],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 41,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=xWmtv1dT7WI&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-62.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "63",
    part: ["События"],
    title: "О работе с бездомными",
    description: [
      "Первая библиотечная конференция «Всё сложно» прошла 6-7 ноября 2019 года. В рамках конференции эксперты говорили о работе с аудиториями, которые требуют специальных знаний и навыков: люди с ОИР, мигранты и бездомные.",
      "Мария Реимбердиева, координатор проектов московского филиала благотворительной организации «Ночлежка», рассказала об особенностях работы с бездомными.",
    ],
    theme: [
      "Целевые аудитории учреждений культуры",
      "Стандарты коммуникации: общение",
    ],
    type: ["видео", "все сложно", "конференция"],
    actions: [
      {
        id: 42,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=P3Y9cBnFsF8&feature=emb_logo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_63.png" },
    year: ["2019"],
    position: 0,
    date_time: "2019-06-13 18:22:00",
  },
  {
    id: "64",
    part: ["Публикации"],
    title: "15 человек на онлайн-лекции считается провалом",
    description: [
      "Колонка Марии Привловой, генерального директора Центральной библиотеки. им. Н.А. Некрасова, о новых вызовах и правилах для небольших культурных институций в связи с карантинными ограничениями 2020 года, а также о том, зачем театрам и библиотекам возвращаться в офлайн.",
    ],
    theme: [
      "Целевые аудитории учреждений культуры",
      "Аудитория библиотек и практики чтения",
      "Городская среда",
    ],
    type: ["СМИ", "статья"],
    actions: [
      {
        id: 15,
        text: "",
        btn: {
          text: "Читать материал",
          url: "https://strelkamag.com/ru/article/direktor-biblioteki-nekrasova-o-nebolshikh-kulturnykh-instituciiah?fbclid=IwAR3IhHEIIDacVZPn78Ls6uS92PRFx4CmoF5Sj2sL8NFqpsEe6Nciznj2dzo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover11.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "65",
    part: ["Подкасты"],
    title:
      "НИИ культуры и отдыха: Библиотека им Н.А. Некрасова в подкасте Парка Горького",
    description: [
      "Подкасты от Парка Горького «НИИ культуры и отдыха» — это беседы с людьми из самых разных сфер о том, что значит отдых для жителя современного мегаполиса, из чего он складывается и как москвичам удается вплетать культурный досуг в свой безумный график.",
      "В выпуске 16 сентября 2020 года Алина Богаткова и Екатерина Фадейкина говорят о том, из чего состоят культурная жизнь и отдых горожан и как в них вписываются библиотеки.",
      "Ведущий: Борис Болелов, руководитель управления по специальным проектам Парка Горького, радиоведущий, журналист.",
    ],
    theme: ["Аудитория библиотек и практики чтения", "Городская среда"],
    type: ["СМИ", "аудио"],
    actions: [
      {
        id: 16,
        text: "",
        btn: {
          text: "Слушать на Castbox",
          url: "https://castbox.fm/va/3182924",
        },
      },
      {
        id: 17,
        text: "",
        btn: {
          text: "Слушать на Яндекс Музыке",
          url: "https://music.yandex.ru/album/11751050",
        },
      },
      {
        id: 18,
        text: "",
        btn: {
          text: "Слушать на Apple.Podcasts",
          url: "https://podcasts.apple.com/.../па.../id1528160872?fbclid=IwAR1lOJDl9n6AK_mi704hICo8v8cWsrF70fy3N2d9nPWkDFmkcP41ph7nGy8",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover12.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "66",
    part: ["Исследования"],
    title:
      "Мария Привалова о культурном программировании общественных пространств",
    description: [
      "Генеральный директор Центральной универсальной научной библиотеки имени Н.А. Некрасова Мария Привалова рассказывает культурном программировании территорий.",
      "Весенний МАРШ-2017 в Казани.",
    ],
    theme: ["Городская среда", "Кейсы и статистика городов мира"],
    type: ["видео", "семинар", "исследование"],
    actions: [
      {
        id: 56,
        text: "",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=HrdbzNmbHxc",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_66.png" },
    year: ["2017"],
    position: 0,
    date_time: "2017-06-13 18:22:00",
  },
  {
    id: "67",
    part: ["Методические материалы"],
    title:
      "Архив методического кабинета управления культуры Моссовета за XX век",
    description: [
      'В самом конце Великой Отечественной войны при управлении культуры Моссовета был создан методический кабинет. Он проработал до 2016 года, меняя свои юридические формы и названия. На протяжении 70 лет его сотрудники проводили аналитическую и методическую работу для развития культуры столицы. В январе 2017 года кабинет прекратил свое существование, будучи на тот момент уже Государственным автономным учреждением города Москвы "Московский институт социально-культурных программ".',
      "Самые актуальные методические материалы, созданные институтом в XXI веке, собраны на этом сайте, <a href='/'>pro.nekrasovka.ru</a>. Вы встретите их почти во всех разделах на самые разные темы. А наиболее яркие материалы, разработанные методистами в XX веке, доступны на отдельном сайте.",
    ],
    theme: ["Стандарты коммуникации: дизайн", "Культурно-массовые мероприятия"],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 51,
        text: "",
        btn: { text: "Открыть сайт", url: "http://archive.miscp.ru" },
      },
    ],
    image: { url: "Nekrasovka_pro_67.png" },
    year: ["2016"],
    position: 0,
    date_time: "2016-06-13 18:22:00",
  },
  {
    id: "68",
    part: ["Публикации"],
    title:
      "«В мире постправды многое остается за скобками»: интервью директора Некрасовки",
    description: [
      "Интервью Марии Приваловой, генерального директора библиотеки имени Н.А. Некрасова, для Forbes Woman о том, как привлечь людей в библиотеки, в чем особенность работы с подростками, что происходит с библиотеками в регионах и зачем вообще в век интернета нужны книги. Из интервью вы также узнаете, как изменилась библиотека им. Н.А. Некрасова за 4 года, как библиотека работала во время пандемии коронавируса, а также о современном библиотечном консалтинге.",
    ],
    theme: [
      "Библиотечные кейсы",
      "Аудитория библиотек и практики чтения",
      "Оптимизация и автоматизация процессов",
    ],
    type: ["СМИ", "интервью"],
    actions: [
      {
        id: 19,
        text: "",
        btn: {
          text: "Читать материал",
          url: "https://www.forbes.ru/forbes-woman/412649-v-mire-postpravdy-mnogoe-ostaetsya-za-skobkami-direktor-nekrasovki-o-tom-zachem",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover13.png" },
    year: ["2020"],
    position: 0,
    date_time: "2020-06-13 18:22:00",
  },
  {
    id: "69",
    part: ["Подкасты"],
    title: "«РадиоНекрасовка»: программа о жизни библиотек и книг",
    description: [
      "«РадиоНекрасовка» — совместный проект радио «Культура» и Центральной библиотеки им. Н.А. Некрасова.  Программа посвящена устройству книжной индустрии, организации культурных проектов в литературе и самому процессу создания текста и его прочтения. ",
      "Об этом в эфире говорят директор библиотеки Мария Привалова и ее гости: литераторы, издатели, критики, музейщики, просветители и, конечно, библиотекари. ",
      "В каждый выпуск входит обзор актуальных кейсов из мира библиотек и книг, живые истории из оцифрованных библиотечных фондов, а также книжные рекомендации библиотекарей. Программа выходит на частоте 91.6 FM два раза в месяц по понедельникам в 18:05 мск. Подкасты всех выпусков доступны на сайте радио «Культура».",
    ],
    theme: [
      "Библиотечные кейсы",
      "Аудитория библиотек и практики чтения",
      "Комплектование библиотечных фондов",
      "Кейсы и статистика городов мира",
      "Городская среда",
    ],
    type: ["СМИ", "интервью", "аудио"],
    actions: [
      {
        id: 2,
        text: "",
        btn: {
          text: "Слушать на сайте радио",
          url: "https://www.cultradio.ru/brand/audio/id/65487/?fbclid=IwAR39UgeS68YVm7X-QIKGKIfYK7DYSd4dv975mLhSYHc6S-Ax0AvDMZ3bN4c",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2-69.png" },
    year: ["2020", "2021", "2022"],
    position: 0,
    date_time: "2022-08-01 10:51:19",
  },
  {
    id: "70",
    part: ["Методические материалы"],
    title:
      "Регламент работы библиотеки с задолжниками. Издание 2-е, дополненное",
    description: [
      "В работе публичных библиотек часто встречаются ситуации, когда читатели нарушают сроки возврата и долго не возвращают книги в фонд. Такие ситуации создают напряжение между читателем и библиотекарем. Читатель может испытывать неловкость от того, что пропустил срок возврата книги, и опасаться возможных санкций. В случае утери книги эти опасения усиливаются. Библиотекарь же может ощущать стресс из-за необходимости обращаться к незнакомому человеку с требованиями, пусть даже и законными. Но у библиотеки же, в свою очередь, есть задача по обеспечению сохранности фонда.",
      "Данное методическое руководство написано для того, чтобы дать библиотекам ясный механизм действий в случаях, когда читатель не вернул документы в фонд.",
      "Первая версия этого издания вышла в 2021 году. Год спустя мы дополнили его еще одним этапом коммуникации — отправкой досудебной претензии (п. 1.5 в оглавлении). Опыт нашей библиотеки показывает, что более четверти пользователей, получивших досудебную претензию на свой e-mail, возвращают книги или добровольно выплачивают компенсацию. А это в свою очередь помогает сократить процессуальные издержки библиотеки.",
    ],
    theme: [
      "Стандарты коммуникации: общение",
      "Комплектование библиотечных фондов",
      "Библиотечные кейсы",
    ],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 95,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/взаимодействие_задолжники.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover2.png" },
    year: ["2022"],
    position: 0,
    date_time: "2022-07-21 10:51:19",
  },
  {
    id: "71",
    part: ["Методические материалы"],
    title: "Положение о работе с коллекциями",
    description: [
      "Коллекции встречаются в библиотеках достаточно часто. Вместе с тем, в библиотечном мире нет общепринятого подхода к таким материалам.",
      "В 2021 году мы проанализировали существующие практики работы с коллекциями и сформулировали основные подходы к их формированию и поддержке. Предлагаем разработанный нами шаблон положения, которое поможет выстроить работу с коллекциями от момента создания до полки в читальном зале.",
    ],
    theme: ["Комплектование библиотечных фондов"],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 96,
        text: "Шаблон Положения о работе с коллекциями.",
        btn: {
          text: "Скачать DOCX",
          url: "https://nekrasovka.ru/file/1652457605.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover3.png" },
    year: ["2021"],
    position: 0,
    date_time: "2021-06-13 18:22:00",
  },
  {
    id: "72",
    part: ["Методические материалы"],
    title:
      "Профили библиотеки на онлайн-картах: руководство по созданию и ведению",
    description: [
      "Этот документ разработан, чтобы помочь библиотекам эффективно вести профили в трех наиболее популярных в России онлайн-картах: Яндекс Карты, Google Карты и 2ГИС. Пособие также может быть полезно для любого учреждения культуры, поскольку встречающаяся библиотечная специфика не мешает адаптировать рекомендации под нужный вид деятельности.",
      "В пособии разбираются только возможности бесплатного размещения на онлайн-картах Яндекс Карты, Google Карты и 2ГИС. Описанные далее в главах ограничения функционала могут отсутствовать при платном размещении. Точную информацию о возможностях платного размещения нужно уточнять у представителей онлайн-карт.",
      "В продолжение этой темы вы можете посмотреть <a href='/documents/73'>две короткие видеолекции</a> о том, как отвечать на комментарии пользователей на онлайн-картах.",
    ],
    theme: [
      "Стандарты коммуникации: общение",
      "Стандарты коммуникации: дизайн",
    ],
    type: ["методическая разработка"],
    actions: [
      {
        id: 97,
        text: "",
        btn: {
          text: "Открыть PDF",
          url: "https://pro.nekrasovka.ru/files/карточки_библиотек_на_онлайн_картах.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_MAPS.png" },
    year: ["2022"],
    position: 0,
    date_time: "2022-10-07 10:51:19",
  },
  {
    id: "73",
    part: ["Методические материалы"],
    title: "Правила коммуникации на онлайн-платформах. Короткие лекции",
    description: [
      "Эти короткие видеолекции рассказывают о том, как можно работать с отзывами пользователей на различных онлайн-платформах, в том числе в соцсетях, вашем канале в Telegram и на онлайн-картах. Валерия Качур, ведущий специалист нашего отдела научно-методической и научно-исследовательской работы, подготовила их на основе собственного опыта и <a href='/documents/5'>стандартов коммуникации</a>, которыми мы пользуемся в своей работе.",
      "Также они хорошо дополняют наше подробное <a href='/documents/72'>Руководство по созданию и ведению профилей библиотеки на онлайн-картах</a>.",
    ],
    theme: ["Стандарты коммуникации: общение"],
    type: ["видео"],
    actions: [
      {
        id: 98,
        text: "Общие правила коммуникации в соцсетях, мессенджерах и онлайн-картах.",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=bcuLKxbH5BQ",
        },
      },
      {
        id: 99,
        text: "Как отвечать на разные типы комментариев на онлайн-платформах",
        btn: {
          text: "Смотреть видео",
          url: "https://www.youtube.com/watch?v=OFgmGXnICoo",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_Kachur.png" },
    year: ["2022"],
    position: 0,
    date_time: "2022-10-18 10:51:19",
  },
  {
    id: "74",
    part: ["Методические материалы"],
    title:
      "Положение о порядке обеспечения сохранности документов при экспонировании",
    description: [
      "Все чаще на выставках рядом и наравне с музейными предметами можно увидеть книги. Кураторы обращаются к старинным, редким и ценным изданиям, чтобы раскрыть с их помощью нетривиальный сюжет или передать атмосферу прошлого.",
      "Библиотекари, хранящие фонды, часто сомневаются, стоит ли размещать такие книги в выставочных витринах. С одной стороны, есть желание показывать образцы книжной культуры разных эпох и рассказывать о них. С другой, на библиотечном стеллаже книги сохранятся надежнее и дольше.",
      "Мы подготовили эти шаблоны, чтобы наши коллеги, кураторы и библиотекари, а также все причастные к созданию книжных экспозиций могли ориентироваться на общие правила и представлять широкой аудитории материалы из библиотечных фондов, не опасаясь им навредить.",
    ],
    theme: ["Сохранность библиотечных фондов"],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 99,
        text: "Шаблон Положения о порядке обеспечения сохранности документов при экспонировании",
        btn: {
          text: "Скачать DOCX",
          url: "https://nekrasovka.ru/file/1672295631.docx",
        },
      },
      {
        id: 100,
        text: "Шаблон Договора на предоставление во временное безвозмездное пользование экспонатов",
        btn: {
          text: "Скачать DOCX",
          url: "https://nekrasovka.ru/file/1672295588.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_Apollon.png" },
    year: ["2022"],
    position: 0,
    date_time: "2022-12-29 10:51:19",
  },
  {
    id: "75",
    part: ["Методические материалы"],
    title:
      "Оценка профилей библиотеки в соцсетях: инструментарий и рекомендации",
    description: [
      "Эти материалы можно использовать для того, чтобы проверить, насколько привлекательно и дружелюбно выглядит профиль вашей библиотеки в социальных сетях. Шаблон позволит вам не упустить важные составляющие и структурировать информацию, а презентация и методичка в формате PDF рассказывают, как заполнять шаблон и какие выводы можно сделать на основе полученных данных.",
      "Чтобы сделать ваше онлайн-присутствие более эффективным, можете также обратиться к нашему <a href='/documents/72'>Руководству по созданию и ведению профилей библиотеки на онлайн-картах</a> и <a href='/documents/76'>Инструментарию по оценке сайта библиотеки.</a>",
      "Другие учреждения культуры также могут использовать эти материалы, немного адаптировав шаблоны под свои особенности.",
    ],
    theme: [
      "Стандарты коммуникации: дизайн",
      "Стандарты коммуникации: общение",
    ],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 101,
        text: "Как оценивать качество ведения социальных сетей библиотеки: инструментарий и рекомендации",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/social-networks-assessment-pres.pdf",
        },
      },
      {
        id: 102,
        text: "Методика оценки качества ведения аккаунтов библиотеки в социальных сетях",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/social-networks-assessment-manual.pdf",
        },
      },
      {
        id: 103,
        text: "Шаблон для анализа аккаунтов библиотеки в соцсетях",
        btn: {
          text: "Скачать DOCX",
          url: "https://admin.nekrasovka.ru/file/social-networks-assessment-template.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_social.png" },
    year: ["2022"],
    position: 0,
    date_time: "2023-02-16 09:14:00",
  },
  {
    id: "76",
    part: ["Методические материалы"],
    title: "Оценка сайта библиотеки: инструментарий и рекомендации",
    description: [
      "Эти материалы можно использовать для того, чтобы проверить, насколько информативно и дружелюбно устроен сайт вашей библиотеки (или другого учреждения культуры). Шаблон позволит вам не упустить важные составляющие работы сайта, а презентация в формате PDF рассказывает, как заполнять шаблон и какие выводы можно сделать на основе собранной информации.",
      "Чтобы сделать ваше онлайн-присутствие более эффективным, можете также обратиться к нашему <a href='/documents/72'>Руководству по созданию и ведению профилей библиотеки на онлайн-картах</a> и <a href='/documents/75'>Инструментарию по оценке профилей библиотеки в соцсетях.</a>",
    ],
    theme: ["Стандарты коммуникации: дизайн"],
    type: ["методическая разработка", "шаблон документа"],
    actions: [
      {
        id: 104,
        text: "Как оценивать качество сайта библиотеки: инструментарий и рекомендации",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/website-assessment-pres.pdf",
        },
      },
      {
        id: 105,
        text: "Шаблон для анализа сайта библиотеки",
        btn: {
          text: "Открыть DOCX",
          url: "https://admin.nekrasovka.ru/file/website-assessment-template.docx",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_website.png" },
    year: ["2022"],
    position: 0,
    date_time: "2023-02-16 10:00:00",
  },
  {
    id: "77",
    part: ["Исследования"],
    title:
      "Культурные центры. Цели и задачи, источники финансирования и индикаторы успеха",
    description: [
      "Перед вами обзор того, как современные культурные центры определяют цели и задачи своей работы; каковы эти цели и задачи по отношению к широкой аудитории, к культурной сфере и к городу; из каких источников может финансироваться деятельность культурных центров; какие показатели позволяют описать качество и успешность их работы.",
      "В основу обзора легли стратегические документы, проекты и событийная программа крупных культурных центров России и других стран.",
      "Этот документ можно использовать, с одной стороны, как источник идей для новых проектов и направлений работы в культурных центрах, а с другой стороны, как «чек-лист», с которым можно сверять стратегию развития сети культурных центров в целом",
    ],
    theme: [
      "Целевые аудитории учреждений культуры",
      "Культурно-массовые мероприятия",
      "Кейсы и статистика городов мира",
    ],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 106,
        text: "Обзор работы современных российских и зарубежных культурных центров",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/contemporary-culture-centers-overview.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_culture.png" },
    year: ["2023"],
    position: 0,
    date_time: "2023-04-23 18:22:00",
  },
  {
    id: "78",
    part: ["Методические материалы"],
    title: "Определитель декоративной бумаги в книжном переплёте",
    description: [
      "Специалисты, изучающие историю книги, знают, как непросто бывает описать книжный переплёт, особенно — определить тип и узор декоративной бумаги, использованной для обложек и форзацев.",
      "Специалистам отдела редких изданий и коллекций Некрасовки эта проблема знакома не понаслышке, так как мы храним и описываем книги, переплетённые с использованием разнообразных мраморных, клейстерных, тиснёных бумаг. В помощь себе и нашим коллегам мы создали определитель, в котором мы собираем образцы типов и узоров бумаг, приводим их названия на русском, английском, французском и немецком языках и краткие описания.",
      "В первой версии определителя представлены наиболее распространённые узоры мраморной бумаги из коллекций библиотеки имени Н.А. Некрасова и Научной библиотеки МГУ имени М.В. Ломоносова. В дальнейшем мы будем дополнять определитель другими типами и узорами.",
    ],
    theme: ["Редкие книги"],
    type: ["методическая разработка", "исследование"],
    actions: [
      {
        id: 107,
        text: "",
        btn: {
          text: "Открыть определитель",
          url: "https://biblioteka.nekrasovka.ru/opredelitel",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_opred.png" },
    year: ["2023"],
    position: 0,
    date_time: "2023-05-31 14:56:00",
  },
  {
    id: "79",
    part: ["Образовательные программы"],
    title:
      "Онлайн-курс «Стандарты обслуживания в современной публичной библиотеке»",
    description: [
      "Мы разработали обучающий курс «Стандарты обслуживания в современной публичной библиотеке». Он будет полезен всем библиотекарям, которые взаимодействуют с читателями, как начинающим, так и опытным, а также руководителям библиотек.",
      "Курс выложен на обучающую платформу Stepik. Он бесплатный и открыт для всех желающих. Для того, чтобы начать проходить курс, нужно зарегистрироваться на платформе.",
    ],
    theme: [
      "Стандарты коммуникации: общение",
      "Стандарты коммуникации: дизайн",
      "Оптимизация и автоматизация процессов",
    ],
    type: ["методическая разработка", "повышение квалификации", "видео"],
    actions: [
      {
        id: 108,
        text: "Курс «Стандарты обслуживания в современной публичной библиотеке»",
        btn: {
          text: "Перейти к курсу",
          url: "https://stepik.org/course/173877",
        },
      },
      {
        id: 109,
        text: "Промо-ролик курса «Стандарты обслуживания в современной публичной библиотеке»",
        btn: {
          text: "Посмотреть видео",
          url: "https://www.youtube.com/watch?v=kLrODqNiMhY",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_pict_bibl.png" },
    year: ["2023"],
    position: 0,
    date_time: "2023-06-08 19:30:00",
  },
  {
    id: "80",
    part: ["Методические материалы"],
    title: "Библиотека по пути: как открывать библиотечные корнеры",
    description: [
      "Перед вами методическое руководство, которое рассказывает про нестационарные пункты книговыдачи в торговых центрах, парках и других общественных пространствах, а также на фестивалях и временных выставках. В основу этого документа лёг собственный опыт нашей библиотеки.",
      "Для Некрасовки библиотечные корнеры — важное направление работы с 2016 года. Они позволили существенно расширить актуальную аудиторию, повысить книговыдачу, пошатнуть негативные стереотипы о библиотеках и привлечь новых партнёров.",
      "Этот документ можно использовать и как основу для повторения опыта Некрасовки в своей библиотеке, и для изучения проекта «Библиотека по пути» и его эффективности.",
    ],
    theme: ["Ремонты в учреждениях культуры", "Библиотечные кейсы"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 110,
        text: "Библиотека по пути: как открыть пункты книговыдачи в общественных пространствах",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/library-corners.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_library_corners_cover.png" },
    year: ["2023"],
    position: 0,
    date_time: "2023-06-13 18:22:00",
  },
  {
    id: "81",
    part: ["События"],
    title: "Всероссийский библиотечный конгресс 2023. Материалы к выступлениям",
    description: [
      "В июне 2023 года в Мурманске прошёл Всероссийский библиотечный конгресс: ХXVII Ежегодная конференция Российской библиотечной ассоциации (РБА).",
      "Специалисты Некрасовки приняли участие в нескольких секциях конгресса и представили доклады, посвящённые темам обеспечения сохранности редких и ценных документов и выбора материалов для транспортировки документов, юридического сопровождения мероприятий библиотеки и претензионно-исковой работы с должниками; рассказали о системе работы с открытым фондом Некрасовки — от расстановки фонда и обеспыливания до книжных выставок; поделились инструментарием для оценки сайта библиотеки; презентовали свежее методическое руководство по открытию пунктов книговыдачи в общественных пространствах; рассказали об опыте разработки и запуска нового интерфейса электронного каталога и о просветительской деятельности основателя и первого директора Некрасовки А.А. Покровского в регионах.",
      "Мы решили собрать вместе наши презентации с конгресса и поделиться ими с коллегами. Надеемся, они будут интересны не только участникам конгресса, но и более широкому профессиональному кругу.",
    ],
    theme: [
      "Редкие книги",
      "Сохранность библиотечных фондов",
      "Стандарты коммуникации: дизайн",
      "Ремонты в учреждениях культуры",
      "Городская среда",
    ],
    type: ["конференция", "методическая разработка"],
    actions: [
      {
        id: 111,
        text: "Система работы с открытым фондом в Центральной универсальной научной библиотеке имени Н.А. Некрасова в Москве",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688561701.pdf",
        },
      },
      {
        id: 112,
        text: "Обеспечение сохранности редких и ценных документов как часть библиотечных процессов (техническая обработка, хранение, экспонирование)",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688561850.pdf",
        },
      },
      {
        id: 113,
        text: "Особенности выбора материалов для транспортировки документов: практические рекомендации",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688561917.pdf",
        },
      },
      {
        id: 114,
        text: "Особенности юридического сопровождения мероприятий библиотеки",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688561981.pdf",
        },
      },
      {
        id: 115,
        text: "Особенности претензионно-исковой работы с пользователями — должниками библиотеки. Опыт Некрасовки",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688562039.pdf",
        },
      },
      {
        id: 116,
        text: "Оценка сайта библиотеки: инструментарий и рекомендации",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688562094.pdf",
        },
      },
      {
        id: 117,
        text: "Новый интерфейс и расширенный функционал электронного каталога как забота о читателе",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688562164.pdf",
        },
      },
      {
        id: 118,
        text: "Просветительская деятельность А.А. Покровского в регионах по материалам его записных книжек, хранящихся в библиотеке имени Н.А. Некрасова",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1688562225.pdf",
        },
      },
      {
        id: 119,
        text: "Библиотека по пути: как открыть пункты книговыдачи в общественных пространствах",
        btn: {
          text: "Открыть сайт",
          url: "https://pro.nekrasovka.ru/documents/80",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_RBA.png" },
    year: ["2023"],
    position: 0,
    date_time: "2023-07-05 15:42:00",
  },
  {
    id: "82",
    part: ["Методические материалы"],
    title: "Проект «Некрасовка». Главные итоги работы за 2016–2023 годы",
    description: [
      "В июне 2023 года Мария Александровна Привалова, которая руководила Центральной универсальной научной библиотекой им. Н.А. Некрасова с 2016 года, передала свой пост новому директору Екатерине Игоревне Светличной.",
      "За 7 лет было сделано очень много, и мы решили собрать в одном месте основные результаты, показать ключевые проекты, а также рассказать о наших принципах работы.",
    ],
    theme: [
      "Библиотечные кейсы",
      "Комплектование библиотечных фондов",
      "Культурно-массовые мероприятия",
      "Оптимизация и автоматизация процессов",
      "Редкие книги",
      "Сохранность библиотечных фондов",
    ],
    type: ["методическая разработка", "фото"],
    actions: [
      {
        id: 120,
        text: "Проект «Некрасовка». Главные итоги работы за 2016–2023 годы",
        btn: {
          text: "Открыть сайт",
          url: "https://biblioteka.nekrasovka.ru/nekrasovka_project",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_16-23.png" },
    year: ["2023"],
    position: 0,
    date_time: "2023-10-10 10:59:00",
  },
  {
    id: "83",
    part: ["События"],
    title: "Всероссийский библиотечный конгресс 2022. Материалы к выступлениям",
    description: [
      "В мае 2022 года в Нижнем Новгороде прошёл Всероссийский библиотечный конгресс: ХXVI Ежегодная конференция Российской библиотечной ассоциации (РБА).",
      "Специалисты отделов комплектования фондов, библиотечно-информационного обслуживания, редких изданий и коллекций, научно-методической и научно-исследовательской работы, а также юридического отдела и дирекции Некрасовки приняли участие в работе нескольких секций конгресса и представили доклады, посвящённые темам организации работы и аттестации сотрудников отдела обслуживания, внедрения единого читательского билета с юридической точки зрения, присутствия библиотеки и коммуникации с читателями на онлайн-картах, формирования специальных коллекций и работы с реестром книжных памятников.",
      "Мы собрали вместе наши презентации с конгресса. Надеемся, они будут интересны не только участникам прошедшего конгресса, но и более широкому кругу наших коллег.",
    ],
    theme: [
      "Библиотечные кейсы",
      "Комплектование библиотечных фондов",
      "Оптимизация и автоматизация процессов",
      "Редкие книги",
      "Стандарты коммуникации: общение",
      "Стандарты коммуникации: дизайн",
    ],
    type: ["методическая разработка", "конференция"],
    actions: [
      {
        id: 121,
        text: "Библиотечное обслуживание в публичной библиотеке: структура отдела и самоуправление",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/RBA_Bogatkova_18th.pdf",
        },
      },
      {
        id: 122,
        text: "Аттестация как инструмент повышения квалификации персонала и справедливого распределения должностей в отделе обслуживания",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/RBA_Bogatkova_17th.pdf",
        },
      },
      {
        id: 123,
        text: "Правовые аспекты внедрения услуги единого читательского билета",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/RBA_Pozdnyakova.pdf",
        },
      },
      {
        id: 124,
        text: "Заметны на карте. Как оформить карточки библиотеки и вести коммуникацию с читателями на онлайн-картах",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/RBA_Kachur_2.pdf",
        },
      },
      {
        id: 125,
        text: "Формирование специальных коллекций в библиотечном фонде на примере Центральной библиотеки им. Н.А. Некрасова",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/RBA_Shlykova.pdf",
        },
      },
      {
        id: 126,
        text: "Коллекция Б.А. Вилинбахова в фондах Центральной библиотеки имени Н.А. Некрасова: об экслибрисах и не только",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/RBA_Kostyukovich.pdf",
        },
      },
      {
        id: 127,
        text: "Год работы с Реестром книжных памятников: теоретические подходы и практические решения",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/RBA_Zaytseva.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_RBA_22.png" },
    year: ["2022"],
    position: 0,
    date_time: "2022-06-10 10:59:00",
  },
  {
    id: "84",
    part: ["Методические материалы"],
    title:
      "Помощь людям в кризисных ситуациях: полезные материалы для посетителей",
    description: [
      "Библиотека может не только оказывать библиотечные услуги, но и играть активную роль в формировании комфортной и безопасной городской среды через профессиональную работу с информацией.",
      "Мы подготовили буклеты с информацией об организациях и проектах, которые помогают людям в трудных жизненных ситуациях, и собрали их в виде небольшого <a href='https://biblioteka.nekrasovka.ru/crisis_help' target='_blank'>сайта</a>, где можно найти информацию о следующих видах помощи:",
      "• помощь женщинам в кризисной ситуации,",
      "• помощь пострадавшим от насилия и эксплуатации,",
      "• помощь беженцам и людям с опытом миграции,",
      "• психологическая помощь подросткам,",
      "• помощь людям в сложной материальной ситуации,",
      "• помощь бездомным людям в Москве.",
      "В буклеты вошли организации и проекты, помогающие людям в Москве, а также организации, которые консультируют онлайн и по телефону, т.е. фактически работают по всей стране.",
      "Мы предлагаем использовать наши буклеты как отправную точку для создания собственных информационных материалов для людей в трудных жизненных ситуациях, выбирая аудитории и организации, наиболее актуальные для вашего учреждения культуры и вашего города.",
      "В данном документе мы делимся нашим опытом работы над этим проектом и предлагаем рекомендации о том, как библиотекари могут пользоваться такими буклетами в работе с посетителями.",
    ],
    theme: [
      "Целевые аудитории учреждения культуры",
      "Городская среда",
      "Стандарты коммуникации: общение",
      "Стандарты коммуникации: дизайн",
    ],
    type: ["методическая разработка"],
    actions: [
      {
        id: 128,
        text: "Помощь людям в кризисных ситуациях",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1711726782.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_pict_crisis.png" },
    year: ["2024"],
    position: 0,
    date_time: "2024-03-28 15:20:00",
  },
  {
    id: "85",
    part: ["Исследования"],
    title: "Платные услуги библиотек: анализ доходов и примеры услуг",
    description: [
      "Сохраняя свою уникальную особенность – доступность и открытость для максимально широкой аудитории посетителей, независимо от их доходов и социального положения, библиотеки, тем не менее, должны учиться зарабатывать дополнительные средства — не в ущерб своей основной деятельности, а для её развития. Задача этого документа — помочь в поиске идей для расширения линейки платных услуг учреждения.",
      "В документе представлен анализ доходов российских библиотек, а также рассказано о том, какие услуги кроме базовых предлагают российские и зарубежные библиотеки своим посетителям. В отчете использованы данные по самостоятельным городским публичным библиотекам, а также национальным, краевым и областным библиотекам, так как их штат и ресурсы позволяют предлагать максимальное разнообразие услуг. В то же время их пример может оказаться актуальным для библиотек любого масштаба.",
      "Рекомендации в заключительной части документа подскажут, что важно учитывать при разработке линейки платных услуг библиотеки как бюджетного учреждения культуры.",
    ],
    theme: ["Библиотечные кейсы", "Поиск средств"],
    type: ["исследование", "методическая разработка"],
    actions: [
      {
        id: 129,
        text: "Платные услуги библиотек",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1711730750.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_pict_dengi.png" },
    year: ["2024"],
    position: 0,
    date_time: "2024-03-29 19:00:00",
  },
  {
    id: "86",
    part: ["Методические материалы"],
    title: "Книжные рекомендации: методическое пособие для библиотекарей",
    description: [
      "Это методическое пособие посвящено тому, как рекомендовать книги читателям. В нём собраны приёмы и практики, которые помогают уточнять запрос читателя, подбирать варианты, которые можно посоветовать, и обосновывать свой выбор для читателя. Пособие призвано помочь библиотекарям эффективно рекомендовать книги даже в сложных случаях, когда запрос читателя выходит за сферу интересов библиотекаря, сформулирован непрозрачно или когда читатель сомневается, принимать ли совет библиотекаря.",
    ],
    theme: [
      "Книжные рекомендации",
      "Стандарты коммуникации: общение",
      "Аудитория библиотек и практики чтения",
    ],
    type: ["методическая разработка"],
    actions: [
      {
        id: 130,
        text: "Книжные рекомендации",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1719578952.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_recommend.png" },
    year: ["2024"],
    position: 0,
    date_time: "2024-06-28 16:00:00",
  },
  {
    id: "87",
    part: ["Методические материалы"],
    title:
      "Новый интерфейс и расширенный функционал электронного каталога: постановка задачи и внедрение",
    description: [
      "Электронный каталог – один из основных инструментов взаимодействия библиотеки с читателями. Современная библиотека, ключевая задача которой – предоставление доступа к книгам и информации, должна стремиться сделать этот доступ максимально простым и удобным. Найти, выбрать и заказать книгу в электронном каталоге библиотеки должно быть не сложнее, чем сделать покупку в хорошем интернет-магазине.",
      "В данном документе мы делимся нашим опытом обновления читательского интерфейса электронного каталога и рекомендациями по тому, как подготовить качественное техническое задание для разработчика, организовать тестирование и внедрение нового интерфейса.",
    ],
    theme: ["Оптимизация и автоматизация процессов", "Электронный каталог"],
    type: ["методическая разработка"],
    actions: [
      {
        id: 131,
        text: "Новый интерфейс и расширенный функционал электронного каталога",
        btn: {
          text: "Открыть PDF",
          url: "https://admin.nekrasovka.ru/file/1720630459.pdf",
        },
      },
    ],
    image: { url: "Nekrasovka_pro_cover_window_upd.png" },
    year: ["2024"],
    position: 0,
    date_time: "2024-06-28 15:00:00",
  },
];
